import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Inicio } from './Inicio';
import { Biodiversity } from './components/Biodiversity';
import { BiodiversityPlace } from './BiodiversityPlace';
import { Biodiversity1 } from './components/Biodiversity1';
import { Biodiversity2 } from './components/Biodiversity2';
import { Biodiversity3 } from './components/Biodiversity3';
import { Biodiversity4 } from './components/Biodiversity4';
import { Biodiversity5 } from './components/Biodiversity5';
import { Biodiversity6 } from './components/Biodiversity6';
import { Biodiversity7 } from './components/Biodiversity7';
import { Biodiversity8 } from './components/Biodiversity8';




function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Inicio/>} />
        <Route path="/biodiversity-blog" element={<BiodiversityPlace/>} />
        <Route path="/biodiversity-guide-to-bird-watching-for-beginners" element={<Biodiversity1/>} />
        <Route path="/exploring-aviarys-digital-brain" element={<Biodiversity2/>} />
        <Route path="/comprenhensive-bird-care" element={<Biodiversity3/>} />
        <Route path="/bird-migration" element={<Biodiversity4/>} />
        <Route path="/impact-of-climate-change-on-bird-populations" element={<Biodiversity5/>} />
        <Route path="/art-and-culture-inspired-by-birds" element={<Biodiversity6/>} />
        <Route path="/creating-bird-friendly-garden" element={<Biodiversity7/>} />
        <Route path="/avian-biodiversity" element={<Biodiversity8/>} />
      </Routes>
      
    </BrowserRouter>
  );
}

export default App;
