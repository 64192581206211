import React from 'react'
import { Biodiversity } from './components/Biodiversity'
import { BiodiversityContainers } from './components/BiodiversityContainers'
import { Nav } from './components/Nav'

export const BiodiversityPlace = () => {
  return (
    <><Nav/><Biodiversity /><BiodiversityContainers /></>
  )
}
