import React from 'react'
import { Biodiversity } from './Biodiversity'
import image1 from '../assets/img/1Image.jpg'
import { Nav } from './Nav'

export const Biodiversity1 = () => {
    return (
        <>  <Nav />
            <Biodiversity />
            <section id="our-feature" className="services pt-0">
                <div className="container" data-aos="fade-up" id='primero'>

                    <div className="section-header">
                        <span>COMPLETE GUIDE TO BIRD WATCHING FOR BEGINNERS</span>
                        <h2>COMPLETE GUIDE TO BIRD WATCHING FOR BEGINNERS</h2>
                        <img src={image1} alt="" className="img-fluid"></img>
                    </div>

                    <div className="col-lg-12 col-md-12" data-aos="fade-up"  >
                        <p>Bird watching, or "birdwatching," is an exciting activity that connects you with nature and allows you to appreciate the winged diversity around us. If you are new to this fascinating world, this comprehensive guide will provide you with the tools and knowledge needed to start your bird-watching adventure.</p>
                        <h3>Essential Equipment</h3>
                        <p>First things first: prepare your equipment. A good pair of binoculars is essential to bring birds closer and appreciate the details. Opt for binoculars with moderate magnification (8x42 is a popular choice) for a balance between power and field of view. Additionally, a field guide or an app like AvIAry can be your best ally for identifying species.</p>
                        <h3>Choose the Time and Place</h3>
                        <p>Birds are most active during the early morning and late afternoon. Choose a location with a variety of habitats, such as forests, wetlands, or parks, to increase your chances of seeing different species.</p>

                        <h3>Develop Your Ear</h3>

                        <p>Bird watching is not just about seeing; it's also about listening! Familiarize yourself with the songs and calls of local birds. You can find recordings online or use specific apps to learn how to identify them by sound.</p>

                        <h3>Learn Observation Techniques</h3>

                        <p>When bird watching, try to move slowly and stealthily. Pay attention to details such as colors, sizes, and behaviors. Learn to use light and shadows to distinguish specific features.</p>

                        <h3>Record Your Observations</h3>

                        <p>Keep a bird watching journal or use specialized apps to record the species you encounter. Note details such as the location, date, and any interesting behavior you observe.</p>

                        <h3>Join the Community</h3>

                        <p>Bird watching is even more enriching when you share your experiences with others. Join local bird-watching groups or participate in events. The community will not only provide knowledge but also lasting friendships.</p>

                        <h3>Be Patient and Enjoy</h3>

                        <p>Bird watching is a relaxing activity that requires patience. Don't be discouraged if you don't see all the species on your first outing. Enjoy the process and celebrate each sighting as a small victory.</p>

                        <p>With this guide, you are ready to embark on your bird-watching journey. Remember that each outing is an opportunity to learn and marvel at the winged wonders around you. Good luck, and may your bird-watching adventures be exciting!</p>


                    </div>




                </div>
            </section>
        </>
    )
}
