import React, { useEffect } from 'react';
import * as echarts from 'echarts/core';
import { TitleComponent, LegendComponent } from 'echarts/components';
import { RadarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([TitleComponent, LegendComponent, RadarChart, CanvasRenderer]);

export const Chart = ({ classificationResults }) => {

    useEffect(() => {
        const chartDom = document.getElementById('radar-chart');
        const myChart = echarts.init(chartDom);

        const indicatorData = classificationResults.map(result => ({
            name: result.class,
            max: 10,
        }));

        const seriesData = [{
            type: 'radar',
            data: [{
                value: classificationResults.map(result => result.confidence),
                itemStyle: {
                    // Asignar colores específicos a cada punto del radar
                    color: '#089242', // Puedes cambiar el color aquí
                },
            }],
        }];

        const legendData = classificationResults.map(result => result.class);

        const option = {
            legend: {
                data: legendData,
            },
            radar: {
                indicator: indicatorData,
            },
            series: seriesData,
        };

        myChart.setOption(option);

        // Cleanup when the component unmounts
        return () => {
            myChart.dispose();
        };
    }, [classificationResults]);

    return (
        <div id="radar-chart" style={{ width: '190%', height: '400px' }}></div>
    );
};
