import React from 'react'
import { Biodiversity } from './Biodiversity'
import image1 from '../assets/img/7Image.jpg'
import { Nav } from './Nav'

export const Biodiversity7 = () => {
    return (
        <>  <Nav />
            <Biodiversity />
            <section id="our-feature" className="services pt-0">
                <div className="container" data-aos="fade-up" id='primero'>

                    <div className="section-header">
                        <span>Creating a Bird-Friendly Garden</span>
                        <h2>Creating a Bird-Friendly Garden</h2>
                        <img src={image1} alt="" className="img-fluid"></img>
                    </div>

                    <div className="col-lg-12 col-md-12" data-aos="fade-up"  >
                        <p>Transforming your outdoor space into a haven for birds not only adds natural beauty to your surroundings but also contributes to the well-being of avian populations. In this guide, we provide practical information on designing bird-friendly gardens, offering tips on selecting plants, setting up feeders, and creating shelters to foster a harmonious coexistence between humans and birds.</p>
                        <h3>Choosing Bird-Friendly Plants: A Blooming Buffet</h3>
                        <p>Start your avian oasis by selecting plants that provide both food and shelter for birds. Explore the world of native plants, which attract local bird species with their familiar fruits, seeds, and nectar. Opt for a variety of plant types, including trees, shrubs, and flowers, to create a diverse and inviting landscape that accommodates different bird preferences.</p>
                        <h3>Year-Round Food Sources: Strategic Planting for All Seasons</h3>
                        <p>Ensure a continuous supply of food throughout the year by strategically planting species that produce berries, seeds, or fruits during different seasons. Consider trees and shrubs that provide winter berries, spring blossoms, and fall nuts. This thoughtful planning not only sustains resident birds but also attracts migratory species during their stopovers.</p>

                        <h3>Strategic Placement of Feeders: Inviting Daily Visitors</h3>

                        <p>Supplement natural food sources by strategically placing bird feeders in your garden. Explore different feeder types, from hanging feeders to ground-level trays, catering to the feeding habits of various bird species. Regularly clean and refill feeders to ensure a steady supply of seeds, suet, or nectar, attracting a diverse array of feathered visitors.</p>

                        <h3>Providing Water Sources: Quenching Thirst and Bathing Bliss</h3>

                        <p>Incorporate birdbaths, shallow ponds, or water features into your garden to offer birds a refreshing oasis. Freshwater sources not only quench their thirst but also provide opportunities for bathing, essential for maintaining healthy feathers. Ensure the water is kept clean and free from debris, creating a welcoming environment for birds to frolic.</p>

                        <h3>Nesting Boxes and Sheltered Spots: Homes for Avian Families</h3>

                        <p>Encourage nesting and shelter by installing birdhouses or nesting boxes in your garden. Research the specific requirements of local bird species to ensure the design and placement meet their needs. Consider providing natural shelter as well, such as dense shrubbery or trees, offering birds safe spaces to rest, roost, and raise their young.</p>

                        <h3>Minimizing Pesticide Use: A Healthy Habitat for All</h3>

                        <p>Create a bird-friendly haven by minimizing the use of pesticides and herbicides in your garden. These chemicals can harm birds directly or by eliminating their insect prey. Embrace natural pest control methods, such as introducing beneficial insects, and choose bird-safe alternatives to create a healthy and thriving habitat.</p>

                        <h3>Educating and Engaging: Fostering a Community of Bird Enthusiasts</h3>

                        <p>Share your passion for birds and gardening by educating others in your community. Host workshops, create informational signs, or organize birdwatching events to inspire neighbors to join in creating bird-friendly spaces. Building a community of bird enthusiasts contributes to a broader network dedicated to preserving avian habitats.</p>

                        <h3>Documenting Bird Visitors: Citizen Science in Your Backyard</h3>

                        <p>Transform your garden into a hub for citizen science by documenting the bird species that visit. Keep a birdwatching journal or use mobile apps to record sightings. Contribute valuable data to citizen science projects, aiding researchers in understanding bird populations and migration patterns.</p>

                        <h3>Creating a Tranquil Space for Observation: Enjoying the Avian Symphony</h3>

                        <p>Design a cozy corner in your garden with comfortable seating where you can quietly observe and enjoy the avian activity. Create a space that invites contemplation and connection with nature, fostering a sense of peace and tranquility for both humans and birds.</p>
                        <p>Crafting a bird-friendly garden is a rewarding endeavor that not only enhances your outdoor space but also contributes to the conservation of avian ecosystems. By implementing these practical tips, you can create a haven that not only attracts a variety of bird species but also fosters a deeper appreciation for the wonders of the avian world in your daily life.</p>


                    </div>




                </div>
            </section>
        </>
    )
}
