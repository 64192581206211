import React from 'react'
import { Biodiversity } from './Biodiversity'
import image1 from '../assets/img/2Image.jpg'
import { Nav } from './Nav'

export const Biodiversity2 = () => {
    return (
        <>  <Nav />
            <Biodiversity />
            <section id="our-feature" className="services pt-0">
                <div className="container" data-aos="fade-up" id='primero'>

                    <div className="section-header">
                        <span>Exploring AvIAry's Digital Brain</span>
                        <h2>Exploring AvIAry's Digital Brain</h2>
                        <img src={image1} alt="" className="img-fluid"></img>
                    </div>

                    <div className="col-lg-12 col-md-12" data-aos="fade-up"  >
                        <p>In the captivating universe of AvIAry, artificial intelligence (AI) becomes the engine driving the magic of bird identification through technology. Let's delve into the complex yet fascinating process that unfolds behind the scenes, breaking down the functioning of neural network models and how AvIAry manages to learn and recognize an astonishing diversity of species.</p>
                        <h3>The Foundation: Artificial Neural Networks</h3>
                        <p>At the core of AvIAry's artificial intelligence are artificial neural networks, structures inspired by the workings of the human brain. These networks consist of layers of interconnected nodes, each processing information similarly to how neurons function in our own brains.</p>
                        <h3>Intensive Training</h3>
                        <p>Before AvIAry can identify birds on its own, it must undergo intensive training with extensive data. Images of birds from different species are used to teach the model how to recognize distinctive patterns, from colors and shapes to specific characteristics of each species.</p>

                        <h3>Convolutional Neural Networks (CNN) and Transformers</h3>

                        <p>In AvIAry's arsenal, we find both Convolutional Neural Networks (CNN) and Transformers. CNNs excel at processing images, highlighting key visual features. On the other hand, Transformers excel in understanding complex relationships and contexts, allowing a deeper understanding of information.</p>

                        <h3> Continuous Learning</h3>

                        <p>AvIAry's intelligence is not static; it is a process of continuous learning. As it encounters new images and data, it adjusts its neural connections to improve the accuracy of identifications. This continuous learning means that AvIAry becomes more adept and precise over time.</p>

                        <h3>Contextual Recognition</h3>

                        <p>AvIAry's intelligence is not static; it is a process of continuous learning. As it encounters new images and data, it adjusts its neural connections to improve the accuracy of identifications. This continuous learning means that AvIAry becomes more adept and precise over time.</p>

                        <p>In summary, AvIAry utilizes artificial intelligence, with its neural networks and specialized models, to create a dynamic system that goes beyond simple bird identification. It is an innovative synthesis of technology and nature, allowing us to explore the bird kingdom with a fresh perspective, thanks to advanced artificial intelligence.</p>


                    </div>




                </div>
            </section>
        </>
    )
}
