import React from 'react'

export const Biodiversity = () => {
  return (
    <section id="hero" className="hero d-flex align-items-center">
            <div className="container">
                <div className="confettie-wrap" >
                    <div className="row gy-4 d-flex justify-content-between">
                        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                            <h2 data-aos="fade-up">Biodiversity Place 📖</h2>
                            <h3 id='pajaros' data-aos="fade-up">🐦🐤🦤🦃🦚🕊️🦉🦜🦅🦆🦢</h3>
                            <p data-aos="fade-up" data-aos-delay="100">
                                Here you can find all the information about how to help the birds and how to take care of them, you can learn a lot of tips and tricks to help them and to make them feel better in their natural habitat.
                                <br></br>
                                <br></br>
                                
                            </p>

                            
                        </div>
                        

                    </div>
                </div>
            </div>
        </section>
  )
}
