import React from 'react'
import '../assets/css/main.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import image1 from '../assets/img/1Image.jpg'
import image2 from '../assets/img/2Image.jpg'
import image3 from '../assets/img/3Image.jpg'
import image4 from '../assets/img/4Image.jpg'
import image5 from '../assets/img/5Image.jpg'
import image6 from '../assets/img/6Image.jpg'
import image7 from '../assets/img/7Image.jpg'
import image8 from '../assets/img/8Image.jpg'
import image9 from '../assets/img/9Image.png'
import { Link } from 'react-router-dom';

export const BiodiversityContainers = () => {
    return (
        <section id="our-feature" className="services pt-0">
            <div className="container" data-aos="fade-up">

                <div className="section-header">
                    <span>INTERESTING ARTICLES</span>
                    <h2>INTERESTING ARTICLES</h2>

                </div>

                <div className="row gy-4">

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                        <Link to="/biodiversity-guide-to-bird-watching-for-beginners">
                            <div className="card">
                                <div className="card-img">
                                    <img src={image1} alt="" className="img-fluid"></img>
                                </div>
                                <h3><a className="stretched-link">Complete guide to bird watching for beginners</a></h3>
                                <p>A guide with basic tips for people starting out in the avian world to get a little guidance.</p>
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                        <Link to="/exploring-aviarys-digital-brain">
                            <div className="card">
                                <div className="card-img">
                                    <img src={image2} alt="" className="img-fluid"></img>
                                </div>
                                <h3><a className="stretched-link">Exploring AvIAry's digital brain</a></h3>
                                <p>For all those who want to understand superficially how the artificial intelligence of the application works.</p>
                            </div>
                        </Link>
                    </div>


                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                        <Link to="/comprenhensive-bird-care">
                            <div className="card">
                                <div className="card-img">
                                    <img src={image3} alt="" className="img-fluid"></img>
                                </div>
                                <h3><a className="stretched-link">Comprehensive bird care</a></h3>
                                <p>The need to know how to care for a bird is fundamental for the correct development of the practice of bird watching in the environment.</p>
                            </div>
                        </Link>
                    </div>


                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                        <Link to="/bird-migration">
                            <div className="card">
                                <div className="card-img">
                                    <img src={image4} alt="" className="img-fluid"></img>
                                </div>
                                <h3><a className="stretched-link">Bird Migration</a></h3>
                                <p>Bird migration is one of the most important phenomena occurring annually in the avian world, learn more about it.</p>
                            </div>
                        </Link>
                    </div>


                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                        <Link to="/impact-of-climate-change-on-bird-populations">
                            <div className="card">
                                <div className="card-img">
                                    <img src={image5} alt="" className="img-fluid"></img>
                                </div>
                                <h3><a className="stretched-link">Impact of Climate Change on Bird Populations</a></h3>
                                <p>Climate change is one of the main problems that avian species face year after year, find out more about this problem.</p>
                            </div>
                        </Link>
                    </div>


                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                        <Link to="/art-and-culture-inspired-by-birds">
                            <div className="card">
                                <div className="card-img">
                                    <img src={image6} alt="" className="img-fluid"></img>
                                </div>
                                <h3><a className="stretched-link">Art and Culture Inspired by Birds</a></h3>
                                <p>Birds have been one of the greatest sources of inspiration for artists and creatives throughout history, check out some of the most interesting cases.</p>
                            </div>
                        </Link>

                    </div>

                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
                        <Link to="/creating-bird-friendly-garden">
                            <div className="card">
                                <div className="card-img">
                                    <img src={image7} alt="" className="img-fluid"></img>
                                </div>
                                <h3><a className="stretched-link">Creating bird-friendly garden</a></h3>
                                <p>Creating a bird sanctuary is always a good idea, learn how to create your own.</p>
                            </div>
                        </Link>
                    </div>


                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="800">
                        <Link to="/avian-biodiversity">
                            <div className="card">
                                <div className="card-img">
                                    <img src={image8} alt="" className="img-fluid"></img>
                                </div>
                                <h3><a className="stretched-link">Avian biodiversity</a></h3>
                                <p>Knowing the great biodiversity of birds that we have in different parts of the planet is something necessary nowadays.</p>
                            </div>
                        </Link>
                    </div>


                    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="900">
                            <div className="card">
                                <div className="card-img">
                                    <img src={image9} alt="" className="img-fluid"></img>
                                </div>
                                <h3><a className="stretched-link">Coming soon...</a></h3>
                                <p>Stay tuned for new articles about birds and their amazing world.</p>
                            </div>

                    </div>
                </div>


            </div>
        </section>
    )
}
